import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLocation } from '@reach/router'


export default function NotFoundPage() {

  const location = useLocation();
  let country ="DE"
  let lang = "de-DE"
  
  const url = typeof window !=="undefined" ? window.location.href :"";

  if(url.includes(".ch")){
    country ="CH"
  }
  
  return (
    <Layout location={country} path={location.pathname} language={lang}>
  
    <Seo title="404: Not found" lang="de"/>
    <h1>404: Not Found</h1>
    <p>Sie sind auf einer Seite gelandet, welche nicht existiert</p>
  </Layout>
)
}
